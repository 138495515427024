.search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;

    .logo {
      max-width: 300px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .search-page-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    min-width: 750px;

    .item-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;

      .source,
      .company-name,
      .country,
      .registered-activity,
      .standard {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
      }

    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .source {
        margin-right: 0 !important;
      }

      &.left-side {
        position: relative;
        width: 372px;
        margin-right: 20px;
      }

      .info-icon {
        position: absolute;
        top: 7px;
        right: 14%;
        cursor: pointer;
        z-index: 100;
        
        svg {
          fill: #ffffff;
        }

        &.icon-for-company {
          right: 37%;
        }

        &.icon-for-country {
          right: 25%;
        }

        &.icon-for-registered {
          right: 32%;
        }

        &.icon-for-products {
          right: 14%;
        }

        &.icon-for-standard {
          right: 43%;
        }

        &.icon-for-certification {
          right: 14%;
        }
      }

      &.right-side {
        position: relative;
        align-items: flex-start;
        width: 372px;
      }
    }

    .buttons-group {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .reset-button {
        background: #f47820;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
        text-shadow: 2px 2px 1px #7a7a7a;
        margin-left: 12px;

        &:hover {
          transition: all 0.35s ease;
          background: rgb(251, 136, 54);
        }

        span {
          font-weight: 700;
        }
      }
    }

    .company-name-wrapper,
    .source-wrapper,
    .country-wrapper,
    .registered-activity-wrapper,
    .certified-product-wrapper,
    .standard-wrapper {
      display: flex;
      align-items: flex-end;

      .button {
        border-radius: 6px;
      }

      .source-button,
      .source-button-delete,
      .country-buttom,
      .country-buttom-delete,
      .certified-product-button, {
        margin: 4px 4px 4px 0;
      }

      .company-name-button,
      .company-name-button-delete,
      .registered-activity-button,
      .registered-activity-button-delete,
      .standard-button {
        margin: 4px 0 4px 4px;
      }
    }

    .ant-select-selector {
      margin: 4px 0;
      border-radius: 6px;
      overflow: hidden;
    }

    .certified-product-wrapper .certified-product  {
      margin-bottom: 0;
    }

    .certified-product {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: #ffffff;
      text-shadow: 2px 2px 1px #7a7a7a;
    }

    input {
      min-width: 300px;
      border-radius: 6px;
      margin: 4px 0;
    }

    .submit-button {
      width: 120px;
      margin-bottom: 0;

      button {
        width: 100%;
        background: #f47820;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
        text-shadow: 2px 2px 1px #7a7a7a;

        &:hover {
          transition: all 0.35s ease;
          background: rgb(251, 136, 54);
        }

        span {
          font-weight: 700;
        }
      }
    }
  }

  .search-results-table {
    padding: 20px;
    background: #92c83e !important;

    .ant-table-body {
      overflow-y: hidden !important;
      overflow-x: scroll !important;
    }

    .ant-table-thead,
    .ant-table-tbody {
      tr,
      th {
        background: #92c83e;
        color: #ffffff;
        font-size: 14px;

        .google-link {
          color: #ffffff;

          &:hover {
            transition: all 0.35s ease;
            color: #f47820;
          }
        }
      }
    }

    .ant-table-thead {
      tr,
      th {
        font-weight: 400;
        text-shadow: 2px 2px 1px #7a7a7a;
        border-bottom: 3px solid #ffffff;
        letter-spacing: 1px;
        text-align: center;
      }
    }

    .phones-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .export-buttons-group {
    margin-bottom: 20px;

    .export-to-xls {
      margin-right: 12px;
    }

    .export-to-xls,
    .export-to-pdf {
      background: #f47820;
      border: none;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      text-shadow: 2px 2px 1px #7a7a7a;

      &:hover {
        transition: all 0.35s ease;
        background: rgb(251, 136, 54);
      }
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  background: unset;
}

.ant-select-dropdown-hidden {
  top: 40px !important;
}