.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgb(146, 200, 62);
  border-top: 1px solid #FFFFFF;
  color: #FFFFFF;

  .footer-title {
    margin-bottom: 12px;
  }

  .footer-sections-wrapper {
    display: flex;

    .left-side,
    .right-side {
      padding: 0 20px;
      width: 50%;

      li {
        list-style: none;
        line-height: 30px;
      }

      .right-side-title {
        color: red;
      }
    }

    span {
      line-height: 30px;
    }
  }

  .footer-bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    span a {
      margin-left: 4px;
      color: #f47820;
      font-weight: 700;

      &:hover {
        transition: all 0.35s ease;
        opacity: 0.8;
      }
    }
  }
}