@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

html,
body {
  width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}

body {
  font-size: 16px;
  line-height: 1;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  background: #92C83E;
}
