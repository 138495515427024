.login-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .data-base-logo {
      max-width: 300px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .forgot-button {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    font-size: 13px;
    text-align: right;

    a {
      display: inline-block;
      color: #212121;
    }
  }

  .login-form {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .user-name,
    .password {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      margin-bottom: 0;

      label {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 4px;
        color: #ffffff;
        text-shadow: 2px 2px 1px #7a7a7a;
      }

      .ant-form-item-explain-error,
      .ant-form-item-explain-success {
        position: absolute;
        bottom: -24px;
      }
    }

    .password {
      padding-bottom: 0px;
    }

    .submit-button {
      width: 100px;
      margin-bottom: 0;

      button {
        width: 100%;
        background: #f47820;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
        text-shadow: 2px 2px 1px #7a7a7a;

        &:hover {
          transition: all 0.35s ease;
          background: rgb(251, 136, 54);
        }
      }
    }
  }
}
