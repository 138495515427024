.user-management-page {
  padding: 20px;

  .add-new-user {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      background: #f47820;
      border: none;
      border-radius: 6px;
      font-size: 14px;
      color: #ffffff;
      text-transform: uppercase;
      text-shadow: 2px 2px 1px #7a7a7a;

      &:hover {
        transition: all 0.35s ease;
        background: rgb(251, 136, 54);
      }
    }
  }

  .edit-inputs-group {
    display: flex;
    align-items: baseline;
  }

  .admin-title {
    color: red;
    padding-top: 12px;
  }

  .edit-user-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-name,
    .password,
    .user-email {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      margin-right: 12px;
      padding-bottom: 24px;

      input {
        width: 180px;
      }

      label {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        color: #f47820;
        width: 150px;
      }

      .ant-form-item-explain-error,
      .ant-form-item-explain-success {
        position: absolute;
        bottom: -24px;
      }
    }

    .user-email {
      width: 240px;
    }

    .user-name {
      width: 150px;
    }

    .password {
      width: 160px;
    }

    .name,
    .user-password,
    .email {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      max-width: 190px;
      width: 100%;
      text-align: center;
      word-break: break-all;
      overflow: hidden;
    }

    .options-buttons-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;

      button {
        margin-right: 8px;
        padding: 4px 8px;
        background: #f47820;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
        text-shadow: 2px 2px 1px #7a7a7a;

        &:hover {
          transition: all 0.35s ease;
          background: rgb(251, 136, 54);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .user-info-wrapper {
      display: flex;
      flex-direction: column;

      .block-title {
        font-weight: 600;
      }
    }
  }
}

.ant-form-item-label {
  display: flex;
  align-items: center !important;
}

.create-user-form {
  display: flex;
  flex-direction: column;

  .user-name,
  .password,
  .user-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 24px;

    input {
      width: 200px;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 4px;
      color: #f47820;
    }

    .ant-form-item-explain-error,
    .ant-form-item-explain-success {
      position: absolute;
      bottom: -24px;
    }
  }
}
