.header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid #ffffff;
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);

  &-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 25%;

    a {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: #ffffff;
      text-shadow: 2px 2px 1px #7a7a7a;

      &:hover {
        transition: all 0.35s ease;
        color: #f47820;
      }

      &.is-active {
        border-bottom: 2px solid #ffffff;
      }
    }
  }

  .log-out-button {
    background: #f47820;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 2px 2px 1px #7a7a7a;

    &:hover {
      transition: all 0.35s ease;
      background: rgb(251, 136, 54);
    }
  }
}
